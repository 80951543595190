<template>
  <form @submit.prevent="login()">
    <div class="form-group">
      <label for="">Email:</label>
      <input
        type="text"
        class="form-control"
        :class="{ 'is-invalid': formErrors.email }"
        v-model="email"
      />
      <InvalidFeedback :errors="formErrors.email"></InvalidFeedback>
    </div>
    <div class="form-group">
      <label for="">Password:</label>
      <input
        type="password"
        class="form-control"
        :class="{ 'is-invalid': formErrors.password }"
        v-model="password"
      />
      <InvalidFeedback :errors="formErrors.password"></InvalidFeedback>
    </div>

    <!-- <input -->
    <!--   type="submit" -->
    <!--   class="btn btn-primary" -->
    <!--   value="Sign in" -->
    <!--   :disabled="sending" -->
    <!-- /> -->
    <app-button-loading :loading="sending">Sign in</app-button-loading>

    <div class="alert alert-danger mt-3" v-show="errorMessage.length > 0">
      {{ errorMessage }}
    </div>
  </form>
</template>
<script>
import { AuthServices } from "./AuthServices.js";
import InvalidFeedback from "./InvalidFeedback.vue";
import validator from "../utils/validator.js";

const formRules = {
  email: {
    email: true
  },
  password: {
    presence: {
      allowEmpty: false
      // message: "'%{value}' hola amigo",
      // message: ""
    }
  }
};

export default {
  components: {
    InvalidFeedback
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    email: "",
    password: "",
    errorMessage: "",
    sending: false
  }),

  computed: {
    formErrors() {
      return validator(
        {
          email: this.email,
          password: this.password
        },
        formRules
      );
    }
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    login() {
      this.sending = true;
      this.errorMessage = "";
      AuthServices.login(this.email, this.password)
        .then(
          () => {
            this.$emit("logued");
          },
          (error) => {
            this.sending = false;
            this.errorMessage = error.response.data.message;
          }
        )
        .then(() => {
          this.sending = false;
        });
    }
  }
};
</script>

<style scoped></style>
